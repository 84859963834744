
<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :class="{ 'grey lighten-2': dragover }"
    class="pa-10 rounded-xl"
    @click="onPickFile"
  >
    <div class="pt-8 pb-10 d-flex flex-column align-center">
      <v-icon class="mb-6">mdi-upload</v-icon>
      <div class="text-medium">Drop or click to upload file</div>
      <div class="text-caption">
        <span>Supported files: </span>
        <span class="primary--text text-body-2 font-weight-medium mr-2" v-if="supportCsv">CSV</span>
        <span class="primary--text text-body-2 font-weight-medium mr-2" v-if="supportTxt">Txt</span>
        <span class="primary--text text-body-2 font-weight-medium mr-2" v-if="supportExcel">Excel</span>
      </div>
    </div>
    <input
      type="file"
      :multiple="true"
      style="display: none"
      ref="fileInput"
      :accept="getSupportedType"
      @change="onFilePicked"
    />
  </v-card>
</template>

<script>
import { join } from 'lodash'
export default {
  name: 'Upload',
  props: {
    supportCsv: {
      type: Boolean,
      default: true,
    },
    supportTxt: {
      type: Boolean,
      default: true,
    },
    supportExcel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
    }
  },
  methods: {
    async onDrop(e) {
      this.dragover = false
      const files = e.dataTransfer.files
      this.handleFile(files[0])
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },
    async handleFile(file) {
      const fileName = file?.name || ''
      const fileExt = fileName.substring(fileName.lastIndexOf('.'))
      this.readFile(file, fileExt).then((res) => {
        let content = ''
        if (fileExt === '.txt') content = res
        else if (fileExt === '.xlsx' || fileExt === '.csv') {
          res.map(
            (item, index) => (content += `${item.address}, ${item.amount} ${index === res.length - 1 ? '' : '\n'}`)
          )
        } else {
          return this.$alert.error('invalid file')
        }
        this.$emit('uploaded', content)
      })
    },
    readFile(file, fileExt) {
      return new Promise((resolve, reject) => {
        var fr = new FileReader()
        fr.onload = () => {
          const res = fr.result
          if (fileExt === '.csv') {
            resolve(this.$utils.csvJSON(res))
          } else if (fileExt === '.xlsx') {
            resolve(this.$utils.xlsxToJson(res))
          } else if (fileExt === '.txt') {
            resolve(res)
          } else resolve('')
        }
        fr.onerror = reject
        fr.readAsBinaryString(file)
      })
    },
    async onFilePicked(event) {
      const files = event.target.files
      this.handleFile(files[0])
    },
  },
  computed: {
    getSupportedType: function () {
      let arr = []
      this.supportCsv && arr.push('.csv')
      this.supportTxt && arr.push('.txt')
      this.supportExcel && arr.push('.xlsx')
      return join(arr, ', ')
    },
  },
}
</script>
<style >
.v-card--link:before {
  border-radius: 24px !important;
}
</style>